.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgb(172, 109, 109);
  border-radius: 10px;
  padding: 10px;
  margin: 5px 10px;
}

.imageContainer {
  padding-right: 5px;
  display: flex;
  align-items: center;
}

.drinkImage {
  border-radius: 50%;
  width: 55px;
  height: 55px;
}

.titleContainer {
  flex: 2;
}

.drinkTitle {
  font-family: 'Quicksand', sans-serif;
  font-size: 15px;
}

.iconContainer {
  display: flex;
  flex-direction: row;
}

.icon {
  padding: 0 10px;
}