/* drink-details-page.module.css */
.page {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  border-radius: 10px;
  padding: 40px;
  margin: 10px;
  background-color: antiquewhite;
}

.ingredients_container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
  margin-top: 20px;
}

.title {
  font-size: 50px;
  padding-bottom: 10px;
  text-align: center;
}

.drinkImage {
  max-width: 50%;
  max-height: 500px;
  object-fit: contain;
  border-radius: 20px;
}

.ingredients {
  display: flex;
  flex-direction: column;
  flex: 1;
}


.instructions {
  margin-top: 20px;
}
