.modalAppText {
    font-size: 20px;
    margin-bottom: 15px;
    text-align: center;
}

.voteButton {
    background-color: green;
    border-radius: 20px;
    margin: 10px 0;
    padding: 10px 50px;
    color: white;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
}

.closeButton {
    background-color: #2196f3;
    border-radius: 20px;
    padding: 10px 20px;
    color: white;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
}
