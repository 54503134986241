.current-drink-item {
    margin: 10px;
    cursor: pointer;
    transition: transform 0.2s;
}

.current-drink-item:hover {
    transform: scale(1.05);
}

.current-drink-img {
    height: 200px;
    object-fit: cover;
}
