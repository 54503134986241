.voting-list-container {
    background-color: white;
    margin: 10px;
    border-radius: 10px;
    padding: 10px;
}

.voting-list-title {
    text-align: center;
    font-size: 24px;
    text-decoration: underline;
    margin-bottom: 10px;
}

.voting-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.voting-list-dot {
    font-size: 24px;
    margin-right: 10px;
}

.voting-list-name {
    flex: 1;
    font-size: 20px;
}
