/* profile-banner.module.css */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: oldlace;
  padding: 20px;
  border-radius: 10px;
}

.imageContainer {
  margin-bottom: 10px;
  text-align: center;
  /* Center the text under the image */
}

.profileImage {
  border-radius: 50%;
  height: 100px;
  width: 100px;
  object-fit: cover;
}

.profileName {
  font-size: 1.5em;
  font-weight: bold;
  margin-top: 10px;
  /* Add some space between the image and the name */
}

.statsContainer {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.statItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 600px) {
  .profileImage {
    height: 150px;
    width: 150px;
  }

  .profileName {
    font-size: 2em;
  }
}

@media (min-width: 900px) {
  .profileImage {
    height: 200px;
    width: 200px;
  }

  .profileName {
    font-size: 2.5em;
  }
}