.page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    background-color: #f8f9fa;
    /* Optional background color */
}

.event-list-container {
    width: 100%;
    max-width: 1200px;
    /* Adjust max-width as needed */
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    /* Optional: add some shadow for better appearance */
    background-color: white;
    border-radius: 10px;
}