.page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 20;
    padding: 20px;
}

.page .content {
    width: 100%;
    max-width: 1200px;
}

.button-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-content svg {
    margin-right: 4px;
}