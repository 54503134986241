#error-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    background-color: #f8d7da;
    color: #721c24;
    font-family: Arial, sans-serif;
    padding: 20px;
    box-sizing: border-box;
  }
  
  #error-page h1 {
    font-size: 3em;
    margin-bottom: 20px;
  }
  
  #error-page p {
    font-size: 1.2em;
    margin: 10px 0;
  }
  
  #error-page i {
    color: #495057;
    font-style: normal;
    font-weight: bold;
  }
  