.cardContainer {
  flex: 1;
  align-items: center;
  cursor: pointer;
}

.imageContainer {
  width: 100%;
  position: relative;
  padding-top: 100%; /* Maintain a square aspect ratio */
}

.avatarImage {
  width: 25%;
  height: 25%;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 2;
  object-fit: cover;
}

.drinkImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.placeholderIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: orange;
  border-radius: 60px;
  height: 100%;
}

.placeholderIcon {
  font-size: 80px;
}

.titleOverlay {
  position: absolute;
  min-height: fit-content;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
}

.drinkTitle {
  color: white;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: clamp(12px, 2vw, 24px); /* Responsive font size with min and max limits */
  padding: 10px;
}

/* Media queries for additional fine-tuning */
@media (max-width: 768px) {
  .drinkTitle {
    font-size: clamp(10px, 3vw, 20px); /* Adjust the limits for smaller screens */
  }
}

@media (max-width: 480px) {
  .drinkTitle {
    font-size: clamp(8px, 4vw, 18px); /* Adjust the limits for even smaller screens */
  }
}
