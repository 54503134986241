
.frontpage-container {
  background-image: linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url("../resources/images/top-view-frame-with-drink-glass-copy-space.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  height: 100vh;
  

}

.maintext {
  padding: 20px;
  color: azure;
  background: rgba(57, 57, 57, 0.5);
  z-index: 1; /* Set a positive z-index to position the text above the image */
}


.ptext{
  text-align: left;
  font-size: 25px;
}

.htext {
  color: rgb(242, 170, 89);
  font-size: 50px;
}


.attribute-link {
  position: absolute;
  bottom: 10px;  /* Adjust as needed */
  right: 10px;   /* Adjust as needed */
}
