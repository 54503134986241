.publish-drink-page {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.info-container {
    text-align: center;
    margin: 20px 0;
}

.info-title {
    font-size: 2rem;
    margin-bottom: 10px;
}

.info-text {
    font-size: 1.2rem;
    margin-bottom: 20px;
}

.button-fixed-container {
    position: fixed;
    bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    background-color: #f8f9fa;
}

@media (min-width: 768px) {
    .publish-drink-page {
        max-width: 700px;
        margin: 0 auto;
    }

    .info-title {
        font-size: 2.5rem;
    }

    .info-text {
        font-size: 1.5rem;
    }

    .button-fixed-container {
        bottom: 20px;
        width: calc(100% - 40px); /* Adjust for padding */
    }
}
