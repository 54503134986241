.edit-profile-page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    background-color: #f8f9fa;
}

.edit-profile-content {
    max-width: 600px;
    width: 100%;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.crop-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 80px;
}

.edit-profile-image-container {
    position: relative;
    width: 150px;
    height: 150px;
    margin: 0 auto;
    cursor: pointer;
}

.edit-profile-image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.edit-profile-image-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #f0f0f0;
    color: #888;
    font-size: 100px;
    /* Adjust the font size as needed */
}

.edit-profile-icon-overlay {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: #3513f5;
    border-radius: 50%;
    padding: 8px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.edit-profile-hidden-file-input {
    display: none;
}

.edit-profile-button-content {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}

.edit-profile-content.mt-3 {
    display: flex;
    justify-content: flex-end; /* Align items to the right */
}