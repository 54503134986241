.offcanvas-navbar {
    background-color: #343a40;
}

.offcanvas-brand-container {
    display: flex;
    align-items: center;
}

.offcanvas-navbar-brand {
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: 10px;
}

.offcanvas-brand-image {
    height: 35px;
}

.offcanvas-hamburger-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    color: #fff;
    margin-left: auto;
}

.offcanvas {
    background-color: #343a40;
    color: #fff;
}

.offcanvas-nav-link {
    color: #fff;
    margin-bottom: 1rem;
    transition: color 0.3s ease;
}

.offcanvas-nav-link:hover {
    color: #bbb;
}

.offcanvas-nav-link.active {
    color: #ffd700;
    font-weight: bold;
}

.offcanvas-btn-logout {
    color: #fff;
    background-color: #dc3545;
    border: none;
    margin-top: 1rem;
}

.offcanvas-btn-logout:hover {
    background-color: #c82333;
}

.offcanvas-btn-primary {
    margin-top: 1rem;
}

.offcanvas-logged-in-as {
    margin-top: 1rem;
    font-weight: bold;
}

.offcanvas-profile-link {
    display: flex;
    align-items: center;
    margin-top: 1rem;
}

.offcanvas-avatar {
    border-radius: 50%;
    height: 35px;
    width: 35px;
    object-fit: cover;
    margin-bottom: 10px;
}

.offcanvas-user-name {
    margin-bottom: 10px;
    padding-left: 10px;
    font-size: 1rem;
}
