.event-detail-page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 20;
    padding: 20px;
}

.event-detail-page .content {
    width: 100%;
    max-width: 700px;
}