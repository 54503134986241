.edit-drink-page {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8f9fa;
    padding: 20px;
}

.edit-drink-content {
    max-width: 800px;
    width: 100%;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.edit-drink-button-content {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}

.edit-drink-image-container {
    width: 100%; /* Ensure the container takes full width */
    max-width: 600px; /* Adjust the maximum width as needed */
    height: auto; /* Maintain aspect ratio */
    margin: 0 auto; /* Center the container */
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;

}

.edit-drink-image {
    width: 100%;
    max-width: 100%; /* Ensure the image takes full width */
    max-height: 600px; /* Adjust the maximum height as needed */
    border-radius: 8px;
    object-fit: contain;
}

.edit-drink-image-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 400px; /* Set a fixed height for the placeholder */
    background-color: #f0f0f0;
    color: #888;
    font-size: 50px; /* Adjust the font size as needed */
    border-radius: 8px;
}

.edit-drink-hidden-file-input {
    display: none;
}
