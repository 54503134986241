/* ScoreDisplay.module.css */
.scoreContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.5rem; /* Adjust the font size as needed */
  }
  
  .scoreContainer p {
    margin: 0; /* Remove default margins */
    line-height: 1.5rem; /* Match the font-size for better vertical alignment */
  }
  
  .scoreContainer .icon {
    margin-left: 8px; /* Adjust the spacing between text and icon as needed */
    font-size: 4vw; /* Use viewport units for responsive sizing */
  }
  
  .eventScoreContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.5rem; /* Adjust the font size as needed */
  }
  
  .eventScoreContainer p {
    margin: 0; /* Remove default margins */
    line-height: 1.5rem; /* Match the font-size for better vertical alignment */
  }
  
  .eventScoreContainer .icon {
    margin-left: 8px; /* Adjust the spacing between text and icon as needed */
    font-size: 4vw; /* Use viewport units for responsive sizing */
    color: blue; /* Set the color for the event score icon */
  }
  