.normal-navbar {
    background-color: #343a40;
    min-height: 60px;
}

.navbar-container {
    display: flex;
    align-items: center;
}

.normal-brand-container {
    display: flex;
    align-items: center;
    margin-inline: 40px;
}

.normal-navbar-brand {
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: 10px;
}

.normal-brand-image {
    height: 35px;
}

.normal-nav-links {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.normal-nav-link {
    color: #fff;
    margin-right: 1rem;
    transition: color 0.3s ease;
    display: flex;
    align-items: center;
    font-weight: bold;
}

.normal-nav-link:hover {
    color: #bbb;
}

.normal-nav-link.active {
    color: #ffd700;
    font-weight: bold;
}

.ml-auto {
    margin-left: auto;
}

.normal-btn-logout {
    color: #fff;
    background-color: #dc3545;
    border: none;
    display: flex;
    align-items: center;
}

.normal-btn-logout:hover {
    background-color: #c82333;
}

.normal-btn-primary {
    display: flex;
    align-items: center;
}

.normal-auth-section {
    display: flex;
    align-items: center;
}

.normal-logged-in-as {
    color: #fff;
    margin-right: 10px;
}

.normal-profile-link {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.normal-avatar {
    border-radius: 50%;
    height: 35px;
    width: 35px;
    object-fit: cover;
}

.normal-user-name {
    color: #fff;
    font-size: 1rem;
}
