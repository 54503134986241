.event-scoreboard-container {
    background: linear-gradient(135deg, #3c0070, #3a0080, #3a0088);
    padding: 20px;
    border-radius: 10px;
    max-width: 700px;
    margin: 0 auto;
}

.scoreboard-title {
    font-family: 'Quicksand', sans-serif;
    font-size: 30px;
    color: seashell;
    text-align: center;
    padding: 10px;
}

.scoreboard-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    padding: 10px;
    border-radius: 10px;
    background-color: #f5f5f5;
}

.rank-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.rank-text {
    color: white;
    font-size: 20px;
}

.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.drink-image {
    border-radius: 50%;
    width: 55px;
    height: 55px;
}

.drink-placeholder {
    width: 55px;
    height: 55px;
    background-color: grey;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.name-container {
    display: flex;
    flex-direction: column;
}

.drink-text {
    font-family: 'Quicksand', sans-serif;
    color: white;
    font-size: 18px;
}

.creator-text {
    color: white;
    font-size: 13px;
}

.score-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.score-text {
    color: ghostwhite;
    font-weight: bold;
    margin-left: 5px;
}
